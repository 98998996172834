import { Controller } from "@hotwired/stimulus"

console.log('clipboarde loaded');

export default class extends Controller {
  static targets = [ "source" ]

  copy() {
    navigator.clipboard.writeText(this.sourceTarget.innerHTML)
  }
}